import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import Footer from '../components/footer';
import { useParams } from 'react-router-dom';
import Loading from '../components/loading';
import MainContent, { PaddedContent } from '../components/main-content';
import { AthletesSubNav } from '../components/nav';
import { Team } from '../lib/model';
import AthletesList from '../components/athletes-list';

const Team = () => {
  const user = useContext(UserContext);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [team, setTeam] = useState<Team | undefined>(undefined);

  useEffect(() => {
    const fetchTeam = async () => {
      if (!team) {
        const _team = await api(user.accessToken).getTeam(params.teamProfileSlug!!);
        setTeam(_team);
      }
    };

    fetchTeam();
  }, [team?.id]);

  if (!team) {
    return <Loading />;
  }

  return (
    <>
      <AthletesSubNav />
      <PageHeader
        title={
          <>
            <img src={team.logo_urls.thumb} className="mr-3 inline h-10 w-10 rounded-full" />
            {team.name}
          </>
        }
        subTitle={
          <>
            <span>Get to know the {team.name} trail running team</span>
          </>
        }
      />
      <MainContent>
        <PaddedContent>
          <h2 className="mb-3 font-medium">Athlete Roster</h2>
          <p className="mb-4 text-xs text-gray-500">
            Have a correction?{' '}
            <a
              href="https://freetrail.com/contact/"
              target="_blank"
              className="hyperlink underline"
            >
              Get in touch
            </a>
            .
          </p>
        </PaddedContent>
        <AthletesList athletes={team.athletes} featuredAthlete={null} />
      </MainContent>
      <Footer />
    </>
  );
};

export default Team;
