import React, { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink, useLocation } from 'react-router-dom';
import UserContext from '../context/user-context';
import { loginAndRedirect } from '../lib/auth';

const Nav = () => {
  const user = useContext(UserContext);
  const { loginWithRedirect, logout } = useAuth0();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname]);

  const sharedLinkClasses =
    'text-white flex items-center h-full border-b-4 hover:border-purple-haze-100 relative group font-mono text-sm uppercase tracking-wider';
  const navLinkClassNameDesktop = ({ isActive }: { isActive: boolean }) => {
    return (
      `${sharedLinkClasses} ` +
      (isActive
        ? 'border-purple-haze-100 hover:border-b-4'
        : 'border-purple-haze-500 hover:text-white')
    );
  };

  const navLinkClassNameMobile = ({ isActive }: { isActive: boolean }) => {
    return isActive
      ? 'bg-purple-haze-700 text-white block px-3 py-2 font-mono uppercase tracking-wider'
      : 'text-white hover:bg-purple-haze-700 hover:text-white block px-3 py-2 font-mono uppercase tracking-wider';
  };

  return (
    <nav className="bg-purple-haze-500 shadow-md">
      <div className="mx-auto max-w-7xl px-2 lg:px-6">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              type="button"
              className="hover:bg-purple-haze-700 focus:outline-none inline-flex items-center justify-center p-2 text-gray-100 hover:text-white focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex h-full flex-1 items-center justify-center lg:items-stretch lg:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <a href="/">
                <img src="/images/ft-logo.svg" className="invert mt-[-5px] inline-block w-28" />
                <span className="ml-2 italic text-white">fantasy</span>
              </a>
            </div>
            <div className="hidden lg:ml-14 lg:block">
              <div className="flex h-full items-center space-x-12">
                <div></div>
                {user.isAuthenticated && (
                  <NavLink to="/" className={navLinkClassNameDesktop}>
                    Dashboard
                  </NavLink>
                )}
                <NavLink to="/events" end className={navLinkClassNameDesktop}>
                  Events
                </NavLink>
                {user.isAuthenticated && (
                  <NavLink to="/leaderboards" className={navLinkClassNameDesktop}>
                    Leaderboards
                  </NavLink>
                )}
                <div className="group relative h-full">
                  <NavLink to="/athletes" className={navLinkClassNameDesktop}>
                    Athletes
                  </NavLink>
                  <div
                    className="focus:outline-none absolute left-[50%] top-[100%] z-50 -ml-24 hidden w-48 origin-top-right bg-white py-1 text-center shadow-lg ring-1 ring-black ring-opacity-5 group-hover:block"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabIndex={-1}
                  >
                    <NavLink
                      to="/athletes"
                      className="block border-b border-gray-300 px-4 py-3 font-mono text-sm font-medium uppercase text-gray-800 hover:underline"
                      role="menuitem"
                      id="athlete-menu-item-1"
                    >
                      Search
                    </NavLink>
                    <NavLink
                      to={`/troy/${new Date().getFullYear() - 1}`}
                      className="block border-b border-gray-300 px-4 py-3 font-mono text-sm font-medium uppercase text-gray-800 hover:underline"
                      role="menuitem"
                      id="athlete-menu-item-2"
                    >
                      TROY
                    </NavLink>
                    <NavLink
                      to="/athletes/teams"
                      className="block border-b border-gray-300 px-4 py-3 font-mono text-sm font-medium uppercase text-gray-800 hover:underline"
                      role="menuitem"
                      id="athlete-menu-item-3"
                    >
                      Teams
                    </NavLink>
                    <NavLink
                      to={`/athletes/free-agency/${new Date().getFullYear()}`}
                      className="block  px-4 py-3 font-mono text-sm font-medium uppercase text-gray-800 hover:underline"
                      role="menuitem"
                      id="athlete-menu-item-4"
                    >
                      Free Agency
                    </NavLink>
                  </div>
                </div>
                <div className="group relative h-full">
                  <NavLink to="/stats" className={navLinkClassNameDesktop}>
                    Stats
                  </NavLink>
                  <div
                    className="focus:outline-none absolute left-[50%] top-[100%] z-50 -ml-24 hidden w-48 origin-top-right bg-white py-1 text-center shadow-lg ring-1 ring-black ring-opacity-5 group-hover:block"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabIndex={-1}
                  >
                    <NavLink
                      to="/stats/splits"
                      className="block  px-4 py-3 font-mono text-sm font-medium uppercase text-gray-800 hover:underline"
                      role="menuitem"
                      id="athlete-menu-item-1"
                    >
                      Splits
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {user.isAuthenticated ? (
            <div className="absolute inset-y-0 right-0 flex h-full items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
              <div className="relative ml-3 h-full">
                <div className="flex h-full items-center">
                  <span className="mr-4 hidden py-1 font-mono text-white lg:inline-block">
                    {user.displayName}
                  </span>
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    type="button"
                    className="focus:outline-none flex text-sm"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full border-2"
                      src={user.picture}
                      alt=""
                      referrerPolicy="no-referrer"
                    />
                  </button>
                </div>
                {dropdownOpen && (
                  <div
                    className="focus:outline-none absolute right-0 top-[100%] w-48 origin-top-right bg-white py-1 text-center shadow-lg ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabIndex={-1}
                  >
                    <NavLink
                      to="/settings"
                      onClick={() => setDropdownOpen(false)}
                      className="block border-b border-gray-300 px-4 py-3 font-mono text-sm font-medium uppercase text-gray-800 hover:underline"
                      role="menuitem"
                      id="user-menu-item-1"
                    >
                      Settings
                    </NavLink>
                    <a
                      href="#"
                      onClick={() => logout({ returnTo: window.location.origin })}
                      className="block px-4 py-3 font-mono text-sm font-medium uppercase text-gray-800 hover:underline"
                      role="menuitem"
                      tabIndex={-1}
                      id="user-menu-item-2"
                    >
                      Sign Out
                    </a>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="absolute inset-y-0 right-0 flex h-full items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
              <div className="hidden h-full lg:ml-6 lg:block">
                <div className="flex h-full space-x-4">
                  <a
                    href="#"
                    onClick={() => loginAndRedirect(loginWithRedirect)}
                    className={navLinkClassNameDesktop({ isActive: false })}
                  >
                    Sign In
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {mobileMenuOpen && (
        <div className="lg:hidden" id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {user.isAuthenticated && (
              <NavLink to="/" className={navLinkClassNameMobile}>
                Dashboard
              </NavLink>
            )}
            <NavLink to="/events" end className={navLinkClassNameMobile}>
              Events
            </NavLink>
            {user.isAuthenticated && (
              <NavLink to="/leaderboards" className={navLinkClassNameMobile}>
                Leaderboards
              </NavLink>
            )}
            {user.isAuthenticated && (
              <NavLink to="/athletes" className={navLinkClassNameMobile}>
                Athletes
              </NavLink>
            )}
            {user.isAuthenticated && (
              <NavLink to="/stats" className={navLinkClassNameMobile}>
                Stats
              </NavLink>
            )}
            {!user.isAuthenticated && (
              <a
                href="#"
                onClick={() => loginAndRedirect(loginWithRedirect)}
                className={navLinkClassNameMobile({ isActive: false })}
              >
                Sign In
              </a>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export const AthletesSubNav = () => {
  const navLinkClasses =
    'block border-b-4 border-black py-3 uppercase text-white hover:border-gray-500';
  const navLinkClassName = ({ isActive }: { isActive: boolean }) => {
    return (
      `${navLinkClasses} ` +
      (isActive ? 'border-gray-500 hover:border-b-4' : 'border-black hover:text-white')
    );
  };

  return (
    <div className="bg-black font-mono text-sm uppercase sm:hidden">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="min-w-full px-4 align-middle lg:px-8">
          <div className="grid grid-cols-4 text-center">
            <NavLink to="/athletes" end className={navLinkClassName}>
              Search
            </NavLink>
            <NavLink to={`/troy/${new Date().getFullYear() - 1}`} className={navLinkClassName}>
              TROY
            </NavLink>
            <NavLink to="/athletes/teams" className={navLinkClassName}>
              Teams
            </NavLink>
            <NavLink
              to={`/athletes/free-agency/${new Date().getFullYear()}`}
              className={navLinkClassName}
            >
              Moves
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
