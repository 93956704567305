import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../lib/api';
import PageHeader from '../components/page-header';
import UserContext from '../context/user-context';
import Footer from '../components/footer';
import AthleteProfileModal from '../components/athlete-profile-modal';
import { useNavigate, useParams } from 'react-router-dom';
import Flags from 'country-flag-icons/react/3x2';
import MainContent, { PaddedContent } from '../components/main-content';
import { AthletesSubNav } from '../components/nav';
import { Team } from '../lib/model';
import AthletesList from '../components/athletes-list';

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    padding: 4
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#eef766' : '',
    color: state.isSelected ? 'black' : ''
  })
};

const Athletes = () => {
  const user = useContext(UserContext);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [featuredAthlete, setFeaturedAthlete] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [teams, setTeams] = useState<null | Team[]>(null);
  const [nationalities, setNationalities] = useState(null);
  const [query, setQuery] = useState('');
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(params.teamProfileSlug || null);
  const [selectedNationality, setSelectedNationality] = useState(null);
  const [hasMedia, setHasMedia] = useState(false);
  const [filtersDisplayed, setFiltersDisplayed] = useState(Boolean(selectedTeamId));
  const navigate = useNavigate();

  let profileTeam: Team | undefined = undefined;
  if (teams) {
    if (params.teamProfileSlug && selectedTeamId && (teams || []).length) {
      profileTeam = teams.find((t) => t.profile_slug === selectedTeamId);
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      async () => {
        setIsLoading(true);
        const data = await api(user.accessToken).getAthletes(
          query,
          selectedTeamId || '',
          selectedNationality || '',
          hasMedia
        );
        setAthletes(data.athletes);
        setFeaturedAthlete(
          query || selectedTeamId || selectedNationality || hasMedia ? null : data.featured_athlete
        );
        setIsLoading(false);
        setLoaded(true);
      },
      loaded ? 250 : 0
    );

    const fetchTeams = async () => {
      if (!teams) {
        const _teams = await api(user.accessToken).getTeams();
        setTeams(_teams);
      }
    };

    fetchTeams();

    if (!nationalities) {
      api(user.accessToken)
        .getNationalities()
        .then((response) => {
          response.json().then((body) => {
            setNationalities(body.data.nationalities);
          });
        });
    }

    return () => clearTimeout(delayDebounceFn);
  }, [query, selectedTeamId, selectedNationality, hasMedia]);

  useEffect(() => {
    if (params.athleteProfileSlug) {
      setSelectedAthlete({ id: params.athleteProfileSlug });
    }
  }, [params.athleteProfileSlug]);

  return (
    <>
      <AthletesSubNav />
      <PageHeader
        title="Athletes"
        subTitle={
          <>
            <span>Get to know the world’s best trail runners</span>
          </>
        }
      />
      <MainContent>
        <PaddedContent>
          <form className="mb-5" onSubmit={(e) => e.preventDefault()} autoComplete="off">
            <label
              htmlFor="default-search"
              className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-0">
                <svg
                  className="h-5 w-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="focus:border-slate-600 block w-full border-0 border-b border-gray-300 p-4 pl-7 text-sm text-gray-900 focus:ring-0"
                placeholder="Search for an athlete..."
                required
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            {(teams || []).length > 0 && (
              <button
                className="hyperlink mt-4 text-sm underline"
                onClick={() => {
                  setFiltersDisplayed(!filtersDisplayed);
                }}
                type="button"
              >
                {filtersDisplayed ? 'Hide search filters' : 'Show search filters'}
              </button>
            )}
            {filtersDisplayed && (teams || []).length > 0 && (
              <div>
                <div className="mt-4 flex-row gap-4 sm:flex">
                  <div className="mb-4 flex-1 sm:mb-0">
                    <label className="text-sm font-medium">Team</label>
                    <Select
                      className="
                                m-0
                                mt-1
                                block
                                w-full
                                text-sm
                                font-normal text-gray-700"
                      defaultValue={
                        profileTeam
                          ? {
                              value: profileTeam.profile_slug,
                              label: profileTeam.name
                            }
                          : { value: '', label: 'All' }
                      }
                      formatOptionLabel={({ value, label }) => {
                        const team = teams.find((team) => team.profile_slug === value);

                        return (
                          <div>
                            {team && (
                              <img
                                src={team.logo_urls.thumb}
                                className="mr-2 inline h-5 w-5 rounded-full"
                              />
                            )}
                            <span>{label}</span>
                          </div>
                        );
                      }}
                      options={[
                        { value: '', label: 'All' },
                        ...teams.map((team) => ({
                          value: team.profile_slug,
                          label: team.name
                        }))
                      ]}
                      onChange={({ value }) => setSelectedTeamId(value)}
                      styles={selectStyles}
                    />
                  </div>
                  <div className="flex-1">
                    <label className="text-sm font-medium">Nationality</label>
                    <Select
                      className="
                                m-0
                                mt-1
                                block
                                w-full
                                text-sm
                                font-normal text-gray-700"
                      defaultValue={{ value: '', label: 'All' }}
                      formatOptionLabel={({ value, label }) => {
                        const Flag = Flags[value];

                        return (
                          <div>
                            {Flag && <Flag className="mr-2 inline h-5 w-5 flex-shrink-0" />}
                            <span>{label}</span>
                          </div>
                        );
                      }}
                      options={[
                        { value: '', label: 'All' },
                        ...(nationalities || []).map((n) => ({
                          value: n.alpha2,
                          label: n.name
                        }))
                      ]}
                      onChange={({ value }) => setSelectedNationality(value)}
                      styles={selectStyles}
                    />
                  </div>
                  <div className="flex-1"></div>
                </div>
                <div className="mt-6 flex-row gap-4 sm:flex">
                  <div className="flex-1">
                    <input
                      type="checkbox"
                      onChange={(e) => setHasMedia(!hasMedia)}
                      id="has-media"
                      className="mr-2"
                    />
                    <label htmlFor="has-media" className="text-sm font-medium">
                      Browse athlete media
                    </label>
                  </div>
                </div>
              </div>
            )}
          </form>
          <p className="mb-4 mt-8 text-xs text-gray-600">
            Missing profile or incorrect information?{' '}
            <a
              className="hyperlink underline"
              href="https://freetrail.com/contact/"
              target="_blank"
            >
              Contact us
            </a>
            .
          </p>
          {selectedAthlete && (
            <AthleteProfileModal
              athleteId={selectedAthlete.id}
              firstName={selectedAthlete.first_name}
              lastName={selectedAthlete.last_name}
              location={selectedAthlete.location}
              onClose={() => {
                setSelectedAthlete(null);
                navigate('/athletes');
              }}
            />
          )}
        </PaddedContent>
        <div className="overflow-x-scroll sm:overflow-hidden">
          {!isLoading && <AthletesList athletes={athletes} featuredAthlete={featuredAthlete} />}
        </div>
      </MainContent>
      <Footer />
    </>
  );
};

export default Athletes;
